/** @jsx jsx */
import { jsx, Image, Box, Heading, Text } from "theme-ui";

export default function FeatureCard({
  src,
  altText = "Fapshi feature",
  title,
  text,
}) {
  return (
    <Box sx={styles.card}>
      <Image src={src} alt={altText} sx={styles.img} />

      <Box sx={styles.wrapper}>
        <Heading sx={styles.wrapper.title}>{title}</Heading>
        <Text sx={styles.wrapper.subTitle}>{text}</Text>
      </Box>
    </Box>
  );
}

const styles = {
  card: {
    display: "flex",
    alignItems: "flex-start",
  },

  img: {
    width: ["60px", null, null, null, "55px", "70px", null, "80px"],
    height: "auto",
    flexShrink: 0,
    mr: [3, 4, null, null, 3, 4, null, 5],
  },
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    mt: "-5px",
    title: {
      fontSize: "22px",
      color: "heading_secondary",
      lineHeight: 1.4,
      fontWeight: 700,
      mb: [2, null, null, null, null, 3],
      mt: "2px",
    },
    subTitle: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: [1.85, null, 2],
    },
  },
};
