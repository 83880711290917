/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import ButtonLink from "./button-link";

export default function GroupButton({ data }) {
  const data1 = data[0];
  const data2 = data[1];

  return (
    <Box sx={styles.buttonGroup}>
      {data1 && <ButtonLink data={data1} />}
      {data2 && <ButtonLink data={data2} />}
    </Box>
  );
}

const styles = {
  buttonGroup: {
    a: {
      textDecoration: "none",
    },
    display: "flex",
    minWidth: "100%",

    "@media screen and (max-width: 356px)": {
      flexDirection: "column !important",
      width: "100%",
    },
  },
};
