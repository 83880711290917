/** @jsx jsx */
import { jsx, Container, Box, Text, Heading, Image } from "theme-ui";
import Briefcase from "assets/core-feature/briefcase.svg";
import PaymentMethod from "assets/core-feature/payment-method.svg";
import TextFeature from "components/text-feature";
import GroupButton from "../components/grouped-button";
import { StaticImage } from "gatsby-plugin-image";
import Grid from "@mui/material/Grid";
import ButtonLink from "../components/button-link";

const data = {
  // subTitle: "Easy-to-use Merchant API",
  subTitle: "",
  title: "Integrate Online Payments on your Website or App",
  features: [
    {
      id: 1,
      imgSrc: PaymentMethod,
      altText: "Briefcase",
      title: "A Fully Integrated Suite ",
      text: "We unified all that is needed to accept payments on websites and apps easily. Fapshi powers payments for online shops, businesses, ecommerce stores, marketplaces, and every other activity that involves online payments.",
    },
    {
      id: 2,
      imgSrc: Briefcase,
      altText: "Wallet",
      title: "SDKs to Ease your work",
      text: "SDKs are available in Nodejs, Python, and PHP to help you easily integrate payments online using our Merchant APIs.",
    },
  ],
};

export default function Merchant() {
  const data1 = {
    btnName: "Try a demo",
    demoBtn: true,
    btnType: "coloredBtn",
    banner: true,
  };

  const data2 = {
    btnName: "Know More",
    btnURL: "https://documentation.fapshi.com/dev-tools.html",
    btnType: "simpleBtn",
  };

  const sdkBtn = {
    btnName: "View on GitHub",
    btnURL: "https://github.com/Fapshi/SDKs",
    btnType: "simpleBtn",
  };

  return (
    <section sx={styles.merchant}>
      <Container>
        <Box sx={styles.containerBox}>
          <Box sx={styles.thumbnail}>
            <StaticImage
              src="../assets/images/merchant.svg"
              alt="Person removing money"
              placeholder="blurred"
            />
          </Box>
          <Box sx={styles.contentBox}>
            <Box sx={styles.headingTop}>
              <TextFeature subTitle={data.subTitle} title={data.title} />
            </Box>
          </Box>
        </Box>
        <Grid container spacing={2}>
          {data.features.map((item) => (
            <Grid item xs={12} md={6} key={item.id} sx={{ pl: "0 !important" }}>
              <Box sx={styles.card}>
                <Image src={item.imgSrc} alt={item.altText} sx={styles.img} />

                <Box sx={styles.wrapper}>
                  <Heading sx={styles.wrapper.title}>{item.title}</Heading>
                  <Text sx={styles.wrapper.subTitle}>{item.text}</Text>
                  {item.id === 2 && (
                    <Box sx={{ paddingTop: "1rem", alignSelf: "flex-end" }}>
                      <ButtonLink data={sdkBtn} />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
          <Box sx={styles.btns}>
            <GroupButton data={[data1, data2]} />
          </Box>
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  merchant: {
    mt: "2%",
    pb: "6%",
  },

  containerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: ["column", null, null, "row"],
    pt: "10%",
    pb: "6%",
  },
  thumbnail: {
    pl: [0, 5, 0, null, 7, 95],
    pr: [0, 5, null, null, null, 75, 95],
    order: [2, null, null, 0],
    // width: ["100%", null, 505, 360, 460, 570, null, 670],
    marginBottom: "2rem",
  },
  contentBox: {
    width: ["100%", 450, 550, 350, 500, 570],
    pr: [0, null, "auto", null, null, 80],
    pl: "auto",
    flexShrink: 0,
  },
  headingTop: {
    pl: [0, null, null, null, "35px", null, "55px", 6],
    mb: [3, null, null, null, 1],
    textAlign: ["center", null, null, "left"],
  },
  card: {
    display: "flex",
    alignItems: "flex-start",
    p: [
      "10px 20px 0",
      "20px 16px",
      "10px 20px 16px",
      "20px 16px",
      "16px 16px 0",
    ],
    backgroundColor: "white",
    borderRadius: "10px",
    transition: "all 0.3s",
    width: ["100%", null, "100%"],
    mx: "auto",
    "&:hover": {
      boxShadow: [
        "0px 0px 0px rgba(0, 0, 0, 0)",
        null,
        null,
        null,
        "0px 8px 24px rgba(69, 88, 157, 0.07)",
      ],
      p: "1rem",
    },
  },

  img: {
    width: ["50px", null, "55px"],
    height: "auto",
    flexShrink: 0,
    mr: [3, 4],
  },
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    mt: "-5px",
    title: {
      fontSize: "22px",
      color: "heading_secondary",
      lineHeight: 1.4,
      fontWeight: 700,
      mb: [2, null, null, null, 3],
    },

    subTitle: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: [1.85, null, 2],
    },
  },

  btns: {
    "@media screen and (min-width: 900px)": {
      m: "0 0 0 auto",
    },
    "@media screen and (max-width: 600px)": {
      m: "0 auto",
      width: "100%",
    },
    p: [
      "24px 20px 0",
      "20px 16px 17px",
      "50px 20px 16px",
      "24px 16px",
      "36px 16px 0",
    ],
  },
};
