/** @jsx jsx */
import { jsx, Box, Heading, Text } from "theme-ui";
import ButtonLink from "./button-link";
import GroupButton from "./grouped-button";

export default function TextFeature({
  subTitle,
  title,
  description,
  link,
  btnName,
  btnURL,
  btnType,
  btnData,
}) {
  const buttonData = { btnName, btnURL, btnType };

  return (
    <Box sx={styles.card}>
      <Box sx={styles.wrapper}>
        <Text sx={styles.wrapper.subTitle}>{subTitle}</Text>
        <Heading as="h2" sx={styles.wrapper.title}>
          {title}
        </Heading>
      </Box>
      {
        <div className="description" sx={styles.description}>
          {description && description}{" "}
          {link && (
            <a
              href="https://stores.fapshi.com"
              target="_blank"
              rel="noreferrer"
            >
              Fapshi Stores
            </a>
          )}
        </div>
      }

      {btnData ? (
        <GroupButton data={[buttonData, btnData]} />
      ) : (
        btnName && <ButtonLink data={buttonData} />
      )}
    </Box>
  );
}

const styles = {
  card: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    flexShrink: 0,
    a: {
      m: ["0 auto", null, null, 0],
    },
  },
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    mt: "-5px",
    title: {
      fontSize: ["2rem", null, "2.5rem"],
      color: "heading_secondary",
      lineHeight: [1.35, null, null, 1.3, 1.2],
      fontWeight: "700",
      letterSpacing: "-.5px",
      mb: 5,
    },

    subTitle: {
      fontSize: "16px",
      color: "heading",
      letterSpacing: "2px",
      textTransform: "uppercase",
      fontWeight: "700",
      mb: [2, null, null, null, 3],
      lineHeight: 1.5,
    },
  },
  description: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: [1.85, null, null, 2, null, "2.2"],
    mb: "30px",
  },
};
