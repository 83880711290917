/** @jsx jsx */
import { jsx, Container, Box, Text, Heading, Image } from "theme-ui";
import Money from "assets/core-feature/money.svg";
import Wallet from "assets/core-feature/wallet.svg";
import TextFeature from "components/text-feature";
import GroupButton from "../components/grouped-button";
import { StaticImage } from "gatsby-plugin-image";
import Grid from "@mui/material/Grid";
import { colors } from "../style_dependencies/variables";

const data = {
  // subTitle: "Money Transfer Made Simple",
  subTitle: "",
  title: "Withdraw Funds at Any Time",
  features: [
    {
      id: 1,
      imgSrc: Money,
      altText: "Money",
      title: "24/7 Payouts",
      text: "All funds collected through payment links, merchant transactions, product sales, etc, enter into your Fapshi account. You can withdraw this money 24/7 by simply making a withdrawal.",
    },
    {
      id: 2,
      imgSrc: Wallet,
      altText: "Wallet",
      title: "Payouts are free",
      text: "Fapshi does not charge for payouts! You can payout (withdraw money) through an operator or request a bank transfer.",
    },
  ],
};

export default function Payout() {
  const data1 = {
    btnName: "Sign Up",
    btnURL: "https://dashboard.fapshi.com/register",
    btnType: "coloredBtn",
  };

  const data2 = {
    btnName: "How to Payout",
    btnURL: "https://documentation.fapshi.com/#payouts",
    btnType: "simpleBtn",
  };

  return (
    <section sx={styles.transfer}>
      <Container>
        <Box sx={styles.containerBox}>
          <Box sx={styles.thumbnail}>
            <StaticImage
              src="../assets/images/transfer.svg"
              alt="People making mobile payments"
              placeholder="blurred"
            />
          </Box>
          <Box sx={styles.contentBox}>
            <Box sx={styles.headingTop}>
              <TextFeature subTitle={data.subTitle} title={data.title} />
            </Box>
          </Box>
        </Box>
        <Grid container spacing={2} sx={styles.grid}>
          {data.features.map((item) => (
            <Grid item xs={12} md={6} key={item.id} sx={{ pl: "0 !important" }}>
              <Box sx={styles.card}>
                <Image src={item.imgSrc} alt={item.altText} sx={styles.img} />

                <Box sx={styles.wrapper}>
                  <Heading sx={styles.wrapper.title}>{item.title}</Heading>
                  <Text sx={styles.wrapper.subTitle}>{item.text}</Text>
                </Box>
              </Box>
            </Grid>
          ))}
          <Box sx={styles.btns}>
            <GroupButton data={[data1, data2]} />
          </Box>
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  transfer: {
    borderTop: "1px dashed",
    borderColor: colors.borderColor,
    mt: "6%",
  },
  containerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: ["column", null, null, "row"],
    pt: "10%",
    pb: "2%",
  },
  thumbnail: {
    pl: [0, 5, 0, null, 7, 95],
    pr: [0, 5, null, null, null, 75, 95],
    order: [2, null, null, 0],
    marginBottom: "2rem",
  },
  contentBox: {
    width: ["100%", 450, 550, 350, 500, 570],
    pr: [0, null, "auto", null, null, 80],
    pl: "auto",
    flexShrink: 0,
  },
  headingTop: {
    pl: [0, null, null, null, "35px", null, "55px", 6],
    mb: [3, null, null, null, 1],
    textAlign: ["center", null, null, "left"],
  },
  card: {
    display: "flex",
    alignItems: "flex-start",
    p: [
      "10px 20px 0",
      "20px 16px",
      "10px 20px 16px",
      "20px 16px",
      "16px 16px 0",
    ],
    backgroundColor: "white",
    borderRadius: "10px",
    transition: "all 0.3s",
    width: ["100%", null, "100%"],
    mx: "auto",
    "&:hover": {
      boxShadow: [
        "0px 0px 0px rgba(0, 0, 0, 0)",
        null,
        null,
        null,
        "0px 8px 24px rgba(69, 88, 157, 0.07)",
      ],
      p: "1rem",
    },
  },

  img: {
    width: ["50px", null, "55px"],
    height: "auto",
    flexShrink: 0,
    mr: [3, 4],
  },
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    title: {
      fontSize: "22px",
      color: "heading_secondary",
      lineHeight: 1.4,
      fontWeight: 700,
      mb: [2, null, null, 0],
    },

    subTitle: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: [1.85, null, 2],
    },
  },

  btns: {
    "@media screen and (max-width: 600px)": {
      m: "0 auto",
      width: "100%",
    },
    p: [
      "24px 20px 0",
      "20px 16px 17px",
      "10px 20px 16px",
      "24px 16px",
      "36px 16px 0",
    ],
  },
};
