/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Box } from "theme-ui";
import TextFeature from "components/text-feature";
import { StaticImage } from "gatsby-plugin-image";
import { colors } from "../style_dependencies/variables";

const data = {
  subTitle: "Built for you",
  title: "Sell Products Online via a Link",
  description:
    "Create products and share their links with anyone to receive payments directly on Fapshi. A product could be anything you want to get paid for (books, electronics, fashion wears, accessories, event tickets, etc). If you prefer to receive orders, you can add the products to your store and instead share the link to your store.",
  btnName: "Sign Up",
  btnURL: "https://dashboard.fapshi.com/register",
  btnType: "coloredBtn",
  btnData: {
    btnName: "How to Create a Product",
    btnURL: "https://youtu.be/_3JEMa2YNzA",
    btnType: "simpleBtn",
  },
};

export default function Product() {
  return (
    <section sx={{ variant: "section.product" }} style={styles.product}>
      <Container sx={styles.containerBox}>
        <Box sx={styles.contentBox}>
          <TextFeature
            subTitle={data.subTitle}
            title={data.title}
            description={data.description}
            btnName={data.btnName}
            btnURL={data.btnURL}
            btnType={data.btnType}
            btnData={data.btnData}
          />
        </Box>
        <Box sx={styles.thumbnail}>
          <StaticImage
            src="../assets/images/store.svg"
            alt="Person checking products online"
            placeholder="blurred"
          />{" "}
        </Box>
      </Container>
    </section>
  );
}

const styles = {
  product: {
    borderTop: "1px dashed",
    borderColor: colors.borderColor,
  },
  containerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: ["wrap", null, null, "nowrap"],
    pt: "6rem",
    pb: "4%",
  },
  thumbnail: {
    m: "0 auto",
    display: "flex",
    justifyContent: "center",

    ".gatsby-image-wrapper": {
      width: ["90%", "70%", null, "80%", "90%"],
    },
  },
  contentBox: {
    width: ["100%", 420, 480, 380, 500, 570],
    mx: "auto",
    flexShrink: 0,
    textAlign: ["center", null, null, "left"],
    pr: [0, null, null, 40, "90px"],
    pb: [9, null, null, 3],
    pl: [0, null, null, null, null, 6],
  },
};
