/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Grid } from "theme-ui";
import SectionHeader from "components/section-header";
import FeatureCard from "components/feature-card.js";

import Product from "assets/feature/product.svg";
import Store from "assets/feature/store.svg";
import Wallet from "assets/feature/wallet.svg";
import Receipt from "assets/feature/receipt.svg";
import Invite from "assets/feature/invite.svg";
import PaymentLink from "assets/feature/payment-link.svg";

const data = [
  {
    id: 8,
    imgSrc: Invite,
    altText: "invitation",
    title: "Accept Payments Online",
    text: "Create a service and obtain API keys to integrate payments on your web or mobile app using our suite of common payment methods.",
    path: "https://documentation.fapshi.com#api",
  },
  {
    id: 3,
    imgSrc: PaymentLink,
    altText: "Payment Link",
    title: "Create Payment Links",
    text: "Collect payments for donations, event fees, contributions, etc; Fapshi manages all the payments and record-keeping for you.",
    path: "https://documentation.fapshi.com#payment-links",
  },
  {
    id: 6,
    imgSrc: Wallet,
    altText: "Wallet",
    title: "Bulk Payments",
    text: "Send money to multiple mobile money accounts by clicking a single button; it's simple and easy.",
    path: "https://documentation.fapshi.com#bulk-payments",
  },
  {
    id: 7,
    imgSrc: Receipt,
    altText: "Receipt",
    title: "Send Branded Invoices",
    text: "Fapshi permits you to create and send invoices (with payment details) to your client(s) so you can be paid directly and with proof.",
    path: "https://documentation.fapshi.com#invoicing",
  },
  {
    id: 1,
    imgSrc: Product,
    altText: "Product",
    title: "Create Products and Sell",
    text: "Create product links and receive payments; manage your business online with ease on Fapshi.",
    path: "https://documentation.fapshi.com#products",
  },
  {
    id: 2,
    imgSrc: Store,
    altText: "Store",
    title: "Own an Online Store",
    text: "Verify your account and use your Fapshi store to showcase your products in one place; get paid directly on Fapshi.",
    path: "https://documentation.fapshi.com#stores",
  },
];

export default function Feature() {
  return (
    <section sx={{ variant: "section.feature" }} id="features">
      <Container>
        <SectionHeader
          slogan="Endless Capabilities"
          title="What you can do on Fapshi"
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <a
              href={item.path}
              sx={{ textDecoration: "none", color: "unset" }}
              target="_blank"
              rel="noreferrer"
              key={item.id}
            >
              <FeatureCard
                src={item.imgSrc}
                altText={item.altText}
                title={item.title}
                text={item.text}
              />
            </a>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    pt: [0, null, null, null, null, null, null, 3],
    gridGap: [
      "37px 0",
      null,
      "45px 30px",
      null,
      "50px 30px",
      null,
      null,
      "90px 70px",
    ],
    width: ["100%", "80%", "100%"],
    mx: "auto",
    gridTemplateColumns: [
      "repeat(1,1fr)",
      null,
      "repeat(2,1fr)",
      null,
      "repeat(3,1fr)",
    ],
  },
};
