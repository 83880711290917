/** @jsx jsx */
import { jsx, Text, Heading } from "theme-ui";
import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { colors } from "../style_dependencies/variables";

import CardContent from "@mui/material/CardContent";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

export default function InfoCard({ data }) {
  const { title, description, path } = data;

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };

  return (
    <Card onMouseEnter={onHover} onMouseLeave={onLeave} sx={styles.content}>
      <a href={path} target="_blank" rel="noreferrer">
        <CardContent>
          <Grid container spacing={2} sx={{ alignItems: "center" }}>
            <Grid item xs={10} sx={styles.gridText}>
              <Heading as="h3">{title}</Heading>
              <Text as="p" sx={styles.text}>
                {description}
              </Text>
            </Grid>
            <Grid item xs={2}>
              <ArrowForwardOutlinedIcon
                sx={hover ? styles.changeColor : styles.icon}
              />
            </Grid>
          </Grid>
        </CardContent>
      </a>
    </Card>
  );
}

const styles = {
  content: {
    borderRadius: "8px",
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 1px 5px 0px !important",
    p: "0 8px",
    margin: "0 auto",

    ".css-46bh2p-MuiCardContent-root:last-child": {
      paddingBottom: "16px !important",
    },

    a: {
      textDecoration: "none",
    },
  },
  gridText: {
    pr: "5%",
    h3: { fontSize: "17px", fontWeight: 600, color: colors.headingColor },
  },
  text: {
    color: "text",
    mt: 1,
    fontSize: "15px",
  },
  icon: {
    width: "35px !important",
    height: "35px !important",
    color: "#5c5c5c",
    padding: "5px",
    borderRadius: "50%",
    border: "1px solid #e3e8ee",
  },
  changeColor: {
    backgroundColor: colors.headingColor,
    width: "35px !important",
    height: "35px !important",
    color: "#fff",
    padding: "5px",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: colors.headingColor,
  },
};
