/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Box, Heading, Text, Button } from "theme-ui";
import React, { useState } from "react";
import ButtonLink from "../components/button-link";
import { colors } from "../style_dependencies/variables";
import ModalVideo from "react-modal-video";
import { FaPlayCircle } from "react-icons/fa";
import Grid from "@mui/material/Grid";

import paymentsInfrastructure from "assets/payments-infrastructure.gif";

const buttonData = {
  btnName: "Try a demo",
  demoBtn: true,
  btnType: "coloredBtn",
  banner: true,
};

export default function About() {
  // Popup video status
  const [videoOpen, setVideoOpen] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    setVideoOpen(true);
  };

  return (
    <section sx={styles.about} id="home">
      <Container sx={styles.about.container}>
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={12} md={7}>
            <Box sx={styles.about.box}>
              <Heading as="h2" sx={styles.about.box.heading}>
                What is Fapshi?
              </Heading>
              <Box sx={styles.about.box.text}>
                <Box sx={styles.about.box.text.box1}>
                  <Text as="p">
                    Fapshi is a set of tools that helps you to collect, manage,
                    and disburse payments easily. We have prebuilt SDKs to help
                    you integrate our APIs on your websites, web apps, or mobile
                    apps. Fapshi users can collect all forms of payment through
                    easy-to-create, self-managed payment links.
                  </Text>
                  <Text as="p">
                    You can equally create links for an invoice or product, and
                    own an easy-to-customize online store.
                  </Text>
                </Box>
              </Box>
              <Box sx={styles.groupBtn}>
                <ButtonLink data={buttonData} />
                <>
                  <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={videoOpen}
                    videoId="g9ZohxRmFAU"
                    onClose={() => setVideoOpen(false)}
                  />
                  <Button
                    sx={styles.modalBtn}
                    aria-label="Watch Video"
                    onClick={handleClick}
                  >
                    <FaPlayCircle sx={{ mr: 1 }} /> Watch Video
                  </Button>
                </>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={paymentsInfrastructure}
              sx={{ maxWidth: "100%", width: "100%", height: "100%" }}
              alt="Fapshi's core features"
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  about: {
    pt: ["60px", null, null, null, null, null, "70px", "80px"],
    pb: ["50px", null, null, "60px", null, "60px", "60px"],
    container: {
      display: "flex",
      alignItems: "center",
    },
    box: {
      width: ["100%"],
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      flexShrink: 0,
      pt: [0, null, null, null, null, null, 5, 7],
      pb: ["4rem", null, null, null, null, 0, null],

      heading: {
        fontSize: "2.5rem",
        fontWeight: "600",
        width: ["100%", "80%", null, null, null],
        lineHeight: "1.2",
        color: colors.btnColor,
        letterSpacing: "-2px",
      },

      text: {
        display: ["block", "block", "flex", null, "flex"],
        fontSize: "18px",
        fontWeight: "400",

        p: {
          lineHeight: "1.85",
          my: "1.5rem",
        },

        box1: {
          width: ["100%", null, "95%", null, null],
        },
      },
    },
  },
  modalBtn: {
    display: "flex",
    justifyContent: "center",
    fontSize: "1rem",
    marginLeft: "8px",
    color: colors.btnColor,
    backgroundColor: "white",
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 1px 5px 0px",

    "@media screen and (max-width: 356px)": {
      width: "9rem",
      ml: 0,
      mt: "1rem",
    },
  },
  groupBtn: {
    display: "flex",
    alignItems: "center",
    "@media screen and (max-width: 356px)": {
      flexDirection: "column !important",
      width: "100%",
    },
  },
};
