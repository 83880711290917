import React from "react";
import { StickyProvider } from "contexts/app/app.provider";
import SEO from "components/seo";
import Layout from "components/layout";
import Banner from "sections/banner";
import About from "sections/about";
import CoreFeatureProducts from "sections/core-feature-products";
import CoreFeaturePayouts from "sections/core-feature-payouts";
import CoreFeaturePaymentLink from "sections/core-feature-payment-link";
import CoreFeatureMerchant from "sections/core-feature-merchant";
import CoreFeatureInvoice from "sections/core-feature-invoice";
import Feature from "sections/feature";
import WorkFlowProducts from "sections/workflow-payment-links";
import ActionCards from "sections/info-cards";
import Faq from "sections/faq";

// Load other package css file
import "react-multi-carousel/lib/styles.css";
import "react-modal-video/css/modal-video.min.css";
import "rc-drawer/assets/index.css";

const seoData = {
  title:
    "Fapshi - Collect Payments through Fapshi's APIs or use prebuilt solutions.",
};

export default function IndexPage() {
  return (
    <StickyProvider>
      <Layout>
        <SEO data={seoData} />
        <Banner />
        <About />
        <Feature />
        <CoreFeaturePaymentLink />
        <WorkFlowProducts />
        <CoreFeatureMerchant />
        <CoreFeaturePayouts />
        <CoreFeatureInvoice />
        <CoreFeatureProducts />
        <ActionCards />
        <Faq />
      </Layout>
    </StickyProvider>
  );
}
