/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Box } from "theme-ui";
import TextFeature from "components/text-feature";
import { StaticImage } from "gatsby-plugin-image";
import { colors } from "../style_dependencies/variables";

const data = {
  subTitle: "",
  title: "Collect Payments through a Link",
  description:
    "On Fapshi, you can create a donation link to collect money for donations, all forms of contributions (group contributions, fundraisers, etc). With a variable link, you can collect payments for event tickets or whatever has multiple fixed prices. You can also create simple payment links and share with anyone to receive money.",
  btnName: "Sign Up",
  btnURL: "https://dashboard.fapshi.com/register",
  btnType: "coloredBtn",
  btnData: {
    btnName: "Watch this video",
    btnURL: "https://youtu.be/g9ZohxRmFAU",
    btnType: "simpleBtn",
  },
};

export default function PaymentLink() {
  return (
    <section sx={{ variant: "section.product" }} style={styles.paymentLink}>
      <Container sx={styles.containerBox}>
        <Box sx={styles.contentBox}>
          <TextFeature
            subTitle={data.subTitle}
            title={data.title}
            description={data.description}
            btnName={data.btnName}
            btnURL={data.btnURL}
            btnType={data.btnType}
            btnData={data.btnData}
          />
        </Box>
        <Box sx={styles.thumbnail}>
          <StaticImage
            src="../assets/images/payment-link.svg"
            alt="Person calculating prices on an invoice"
            placeholder="blurred"
          />{" "}
        </Box>
      </Container>
    </section>
  );
}

const styles = {
  paymentLink: {
    borderTop: "1px dashed",
    borderColor: colors.borderColor,
    clipPath: "polygon(0 0, 100% 0, 100% 92%, 0% 100%)",
    "@media screen and (max-width: 767px)": {
      clipPath: "polygon(0 0, 100% 0, 100% 95%, 0% 100%)",
    },
    "@media screen and (max-width: 640px)": {
      clipPath: "polygon(0 0, 100% 0, 100% 98%, 0% 100%)",
    },
    marginTop: "6%",
  },
  containerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: ["wrap", null, null, "nowrap"],
    pt: "3rem",
    pb: "25%",

    "@media screen and (min-width: 767px) and (max-width: 1024px)": {
      pb: "20%",
    },
  },
  thumbnail: {
    m: "0 auto",
    display: "flex",
    justifyContent: "center",

    ".gatsby-image-wrapper": {
      width: ["90%", "70%", null, "80%", "70%"],
    },
  },
  contentBox: {
    width: ["100%", 420, 480, 380, 500, 570],
    mx: "auto",
    flexShrink: 0,
    textAlign: ["center", null, null, "left"],
    pr: [0, null, null, 40, "90px"],
    pb: [9, null, null, 3],
    pl: [0, null, null, null, null, 6],
  },
};
