/** @jsx jsx */
import { jsx, Container } from "theme-ui";
import Grid from "@mui/material/Grid";

import InfoCard from "components/card";

const data = [
  {
    id: 2,
    title: "Collect money via payment links",
    description:
      "Collect payments, sell event tickets, or collect funds for a contribution and more by sharing a link to a payment page.",
    path: "https://youtu.be/g9ZohxRmFAU",
  },
  {
    id: 4,
    title: "Withdraw your funds",
    description:
      "Payouts are free and available 24/7, payout through an operator or request a bank transfer.",
    path: "https://youtu.be/aHI1FPoRG28",
  },
  {
    id: 3,
    title: "Integrate payments online",
    description:
      "Use Fapshi's payment APIs to collect payments on your website or app. We have prebuilt SDKs to ease your work.",
    path: "https://documentation.fapshi.com/dev-tools.html",
  },
  {
    id: 1,
    title: "Do you need a custom store?",
    description:
      "Our team can help you setup your store on Fapshi, or build a custom store for you and integrate all our payment methods.",
    path: "/contact",
  },
];

export default function ActionCards() {
  return (
    <Container sx={styles.gridContainer}>
      <Grid container spacing={2}>
        {data.map((dataItem) => (
          <Grid item xs={12} sm={6} xl={3} key={dataItem.id}>
            <InfoCard data={dataItem} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

const styles = {
  gridContainer: {
    pt: "3rem",
    pb: "5rem",
  },
};
