/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Box } from "theme-ui";
import TextFeature from "components/text-feature";
import { StaticImage } from "gatsby-plugin-image";
import { colors } from "../style_dependencies/variables";

const data = {
  subTitle: "",
  title: "Create Invoices",
  description:
    "Fapshi permits its users to create invoices (requests for payment) and share them with their clients. They can optionally add items, their prices, and quantities to the invoice. Invoices serve as proofs of payment and Fapshi eases the process for you to collect these payments directly.",
  btnName: "Sign Up",
  btnURL: "https://dashboard.fapshi.com/register",
  btnType: "coloredBtn",
};

export default function Invoice() {
  return (
    <section sx={{ variant: "section.product" }} style={styles.invoice}>
      <Container sx={styles.containerBox}>
        <Box sx={styles.contentBox}>
          <TextFeature
            subTitle={data.subTitle}
            title={data.title}
            description={data.description}
            btnName={data.btnName}
            btnURL={data.btnURL}
            btnType={data.btnType}
          />
        </Box>
        <Box sx={styles.thumbnail}>
          <StaticImage
            src="../assets/images/invoice.svg"
            alt="Person calculating prices on an invoice"
            placeholder="blurred"
          />{" "}
        </Box>
      </Container>
    </section>
  );
}

const styles = {
  invoice: {
    marginTop: "5rem",
    borderTop: "1px dashed",
    borderColor: colors.borderColor,
    clipPath: "polygon(0 0, 100% 0, 100% 92%, 0% 100%)",
    "@media screen and (max-width: 767px)": {
      clipPath: "polygon(0 0, 100% 0, 100% 95%, 0% 100%)",
    },
    "@media screen and (max-width: 640px)": {
      clipPath: "polygon(0 0, 100% 0, 100% 98%, 0% 100%)",
    },
  },
  containerBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: ["wrap", null, null, "nowrap"],
    pt: "5rem",
    pb: "5%",

    "@media screen and (max-width: 767px)": {
      pb: "5rem",
    },
  },
  thumbnail: {
    m: "0 auto",
    display: "flex",
    justifyContent: "center",

    ".gatsby-image-wrapper": {
      width: ["90%", "70%", null, "80%", "70%"],
    },
  },
  contentBox: {
    width: ["100%", 420, 480, 380, 500, 570],
    mx: "auto",
    flexShrink: 0,
    textAlign: ["center", null, null, "left"],
    pr: [0, null, null, 40, "90px"],
    pb: [9, null, null, 3],
    pl: [0, null, null, null, null, 6],
  },
};
