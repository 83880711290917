/** @jsx jsx */
import { jsx } from "theme-ui";
import { Container, Box, Heading, Text, Flex } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import BannerBG from "assets/bannerBg.png";
import { colors } from "../style_dependencies/variables";
import GroupButton from "../components/grouped-button";
import Grid from "@mui/material/Grid";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import Avatar from "@mui/material/Avatar";

const data1 = {
  btnName: "Sign Up",
  btnURL: "https://dashboard.fapshi.com/register",
  btnType: "coloredBtn",
  banner: true,
};

const data2 = {
  btnName: "Try a demo",
  demoBtn: true,
  btnType: "simpleBtn",
  banner: true,
};

const features = [
  {
    icon: (
      <DoneOutlinedIcon
        sx={{
          width: "0.55em !important",
          height: "0.55em !important",
        }}
        fontSize="small"
      />
    ),
    text: "Payment gateway",
  },
  {
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.55em !important", height: "0.55em !important" }}
        fontSize="small"
      />
    ),
    text: "Payment links",
  },
  {
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.55em !important", height: "0.55em !important" }}
        fontSize="small"
      />
    ),
    text: "WordPress Plugin",
  },
  {
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.55em !important", height: "0.55em !important" }}
        fontSize="small"
      />
    ),
    text: "SDKs for developers",
  },
  {
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.55em !important", height: "0.55em !important" }}
        fontSize="small"
      />
    ),
    text: "Marketplace",
  },

  {
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.55em !important", height: "0.55em !important" }}
        fontSize="small"
      />
    ),
    text: "Bulk payments",
  },

  {
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.55em !important", height: "0.55em !important" }}
        fontSize="small"
      />
    ),
    text: "Documented APIs",
  },
  {
    icon: (
      <DoneOutlinedIcon
        sx={{ width: "0.55em !important", height: "0.55em !important" }}
        fontSize="small"
      />
    ),
    text: "API Sandbox",
  },
];

export default function Banner() {
  return (
    <section sx={styles.banner} id="home">
      <Container sx={styles.banner.container}>
        <Box sx={styles.banner.contentBox}>
          <Heading as="h1" variant="heroPrimary">
            <span sx={styles.banner.contentBox.h1.simplified}>Simplified </span>
            <br />
            <span sx={styles.banner.contentBox.h1.payments}>Payments </span>
            <br />
            <span sx={styles.banner.contentBox.h1.internet}>
              for the internet
            </span>
          </Heading>
          <Text as="p" variant="heroSecondary">
            Use Fapshi's payment APIs to collect payments on your website or
            app, or use our pre-built solutions to receive payments for your
            events, contributions/donations, or to simply accept payments from
            your clients.
          </Text>
          <Box sx={styles.btns}>
            <GroupButton data={[data1, data2]} />
          </Box>
        </Box>

        <Box sx={styles.banner.imageBox}>
          <StaticImage
            src="../assets/fapshi-dashboard.svg"
            alt="People making payments online"
            placeholder="blurred"
          />
        </Box>
      </Container>
      <Container>
        <Grid container sx={styles.features}>
          {features.map((feature) => (
            <Grid
              item
              key={feature.text}
              xs={6}
              md={3}
              sx={styles.features.grid}
            >
              <Flex sx={styles.features.feature}>
                <Avatar sx={styles.features.feature.avatar}>
                  {feature.icon}
                </Avatar>{" "}
                {feature.text}
              </Flex>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  banner: {
    clipPath: "polygon(0 0, 100% 0, 100% 95%, 0% 100%)",
    "@media screen and (max-width: 767)": {
      clipPath: "polygon(0 0, 100% 0, 100% 98%, 0% 100%)",
    },
    overflow: ["hidden", "initial", null, "hidden"],
    backgroundImage: `url(${BannerBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: "top left",
    backgroundSize: "cover",
    pt: ["150px", null, null, null, null, null, "140px", "130px"],
    pb: ["100px", null, null, "110px", null, 10, "150px"],
    backgroundColor: colors.lightBg,
    container: {
      display: "flex",
      alignItems: "center",
    },
    contentBox: {
      width: ["100%", null, "85%", "55%", "60%", "60%"],
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      flexShrink: 0,
      pt: [0, null, null, null, null, null, 5, 7],

      h1: {
        fontFamily: "'PT Sans Narrow', sans-serif",
        fontWeight: "900",
        lineHeight: [0.9, 1, null, 0.9, 0.9, 0.9],
        color: colors.btnColor,
        letterSpacing: "1px",

        simplified: {
          letterSpacing: ["1px", "1px", null, "1px", "1px", "1px"],
          fontSize: ["4rem", "5rem", null, "5rem", "7.5rem", "8rem"],
        },
        payments: {
          letterSpacing: ["2px", "3px", null, "3px", "3px", "3px"],
          fontSize: ["4rem", "5rem", null, "5rem", "7.5rem", "8rem"],
        },
        internet: {
          letterSpacing: ["-2px", "-2px", null, "-4px", "-4px", "-4px"],
          fontSize: ["4rem", "5rem", null, "5rem", "6.5rem", "7rem"],
        },
      },

      p: {
        lineHeight: "1.5",
        mt: "3",
        color: colors.textColor,
      },
    },

    imageBox: {
      display: ["none", null, null, "block", null, null],
      justifyContent: "center",
      pl: [null, null, null, "2%", "3%", "3%"],
      mr: "-40%",
    },
  },
  btns: {
    "@media screen and (max-width: 356px)": {
      width: "100%",
    },
  },
  sponsorTitle: {
    color: "text",
    fontWeight: 500,
    fontSize: "18px",
    pr: 20,
    flexShrink: 0,
    pb: [2, null, 0],
  },
  sponsorBox: {
    pt: ["45px", null, null, null, null, "80px"],
    flexDirection: ["column", null, "row"],
    alignItems: ["start", null, "center"],
    sponsor: {
      display: "flex",
      alignItems: "center",
      "> a": {
        mr: [5, null, null, 4, 6],
        display: "flex",
        "&:last-of-type": {
          mr: 0,
        },
      },
    },
  },
  features: {
    "@media screen and (min-width: 1024px)": {
      width: "70% !important",
    },

    "@media screen and (max-width: 350px)": {
      flexDirection: "column !important",
      mt: "2rem",
      maxWidth: "100% !important",
    },

    mt: "4.5rem",

    grid: {
      "@media screen and (max-width: 350px)": {
        maxWidth: "100% !important",
      },
    },

    feature: {
      alignItems: "center",

      "@media screen and (max-width: 350px)": {
        mb: "0.5rem",
      },

      avatar: {
        mr: "5px",
        mt: "-2px !important",
        width: "15px !important",
        height: "15px !important",
        backgroundColor: colors.secondary + " !important",
      },
    },
  },
};
