/** @jsx jsx */
import { jsx, Box, Container, Flex, Heading } from "theme-ui";
import SectionHeader from "../components/section-header";
import Accordion from "components/accordion/accordion";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { colors } from "../style_dependencies/variables";

const faqs = [
  {
    isExpanded: true,
    title: "What is Fapshi?",
    contents: (
      <div>
        Fapshi is a set of tools that helps you to collect, manage, and disburse
        payments easily. We have prebuilt SDKs to help you integrate our APIs on
        your websites, web apps, or mobile apps. Fapshi users can collect all
        forms of payment through easy-to-create, self-managed payment links. You
        can equally create links for an invoice or product, and own an
        easy-to-customize online store.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: "Do I need to pay to use Fapshi?",
    contents: (
      <div>
        You do not need to pay to use Fapshi; all you need do is create an
        account (it's free). However, Fapshi offers some services which will
        deduct a minute percentage of your revenue when you use them.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: "Why must I verify my account?",
    contents: (
      <div>
        Verifying your account permits us to know who you are. With this, we can
        better serve you and give you personalized experiences on Fapshi.
      </div>
    ),
  },
  {
    isExpanded: false,

    title: "Why was I debited?",
    contents: (
      <div>
        Fapshi processes payments for multiple merchants. When your debit alert
        reads Fapshi Inc, it simply means that the transaction was made to a
        business or vendor that uses Fapshi for collections.
      </div>
    ),
  },
  {
    isExpanded: false,

    title: "How do I reset my pin?",
    contents: (
      <div>
        Your pin is a code that you use for payouts on Fapshi; when you create
        an account, you'd see the option to create this pin. If for some reason
        you lose your pin, you'd have to{" "}
        <a href="https://support.fapshi.com" target="_blank">
          contact customer support
        </a>{" "}
        for instructions to change it or{" "}
        <a href="https://youtu.be/CgBUuS4Ns2c" target="_blank">
          watch this video
        </a>
        .
      </div>
    ),
  },
  {
    isExpanded: false,

    title: "Does Fapshi keep my money?",
    contents: (
      <div>
        Fapshi does not keep its users' money. All account balances are kept
        with our partner operators. They are the ones who have entities to keep,
        preserve, and secure your money.
      </div>
    ),
  },
];
export default function Faq() {
  return (
    <Box as="section">
      <Container sx={{ pt: 50 }}>
        <SectionHeader
          slogan="Quick Responses"
          title="Frequently Asked Questions"
        />
        <Flex sx={styles.flex}>
          <Box sx={styles.faqWrapper}>
            <Accordion items={faqs} />
          </Box>
          <Box sx={styles.content}>
            <Heading as="h3">
              If your question is not listed here, please check the
              documentation
            </Heading>
            <a
              sx={styles.askButton}
              href="https://documentation.fapshi.com#faqs"
              target="_blank"
              rel="noreferrer"
            >
              See All FAQs
              <ChevronRightIcon sx={styles.askButton.chevron} />
            </a>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

const styles = {
  flex: {
    flexWrap: "wrap",
    flexDirection: ["column", null, null, null, null, "row-reverse"],
    pb: ["70px", null, null, null, "90px", null, "130px"],
  },
  content: {
    flex: ["0 0 100%", null, null, null, "0 0 33.333%"],
    maxWidth: ["100%", null, null, "450px", "100%"],
    mx: ["auto", null, null, null, "0"],
    mb: ["0px", null, null, null, "0"],
    textAlign: ["center", null, null, null, null, "left"],
    mt: ["40px", null, null, null, null, "0"],
    h3: {
      fontSize: ["20px", null, null, null, "24px"],
      lineHeight: [1.5, null, null, null, 1.67],
      color: "heading_secondary",
      fontWeight: 700,
      letterSpacing: "-1.5px",
      mt: "-5px",
      pr: ["0", null, null, null, null, "30px"],
    },
  },
  askButton: {
    display: "inline-flex",
    verticalAlign: "middle",
    backgroundColor: "white",
    textDecoration: "none",
    color: colors.btnColor,
    borderRadius: "20px",
    boxShadow: "rgba(100, 100, 111, 0.1) 0px 1px 5px 0px",
    fontSize: "16px",
    fontWeight: 700,
    p: "4px 14px",
    letterSpacing: "-0.16px",
    mt: "25px",
    cursor: "pointer",
    transition: "all 500ms ease",
    "&:hover": {
      opacity: 0.9,
    },

    chevron: {
      marginTop: "3px",
      marginLeft: "3px",
      fontSize: "1.2rem !important",
    },
  },
  faqWrapper: {
    flex: ["0 0 100%", null, null, null, "0 0 66.666%"],
  },
};
